var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"iot"},[_vm._m(0),_c('div',{staticClass:"img-div"},[_c('img',{attrs:{"src":require("../../assets/imgs/xq1.png"),"alt":""}}),_c('div',{staticClass:"right"},_vm._l((_vm.xqArr),function(item){return _c('div',{key:item.id,staticClass:"right-de"},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.name))])])}),0)])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"line"}),_c('p',{staticClass:"title"},[_vm._v("NB-IOT智能门磁")]),_c('p',{staticClass:"subTitle"},[_vm._v("医学观察、居家隔离小门神！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"geli"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"line"}),_c('p',{staticClass:"title"},[_vm._v("智慧隔离“小门神”")]),_c('p',{staticClass:"subTitle"},[_vm._v("功能全，更懂管理")])]),_c('div',{staticClass:"img-div"},[_c('img',{attrs:{"src":require("../../assets/imgs/xq8.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#F4F8FF"}},[_c('div',{staticClass:"shouming"},[_c('img',{attrs:{"src":require("../../assets/imgs/xq9.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantage"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"line"}),_c('p',{staticClass:"title"},[_vm._v("“小门神”智慧隔离服务系统优势")]),_c('p',{staticClass:"subTitle"},[_vm._v("多房源、网格化管理，智慧系统更高效")])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../../assets/imgs/xq13.png"),"alt":""}}),_c('p',[_vm._v("隔离，人工监测弊端")])]),_c('ul',[_c('li',[_vm._v("无法确定需隔离人员是否外出")]),_c('li',[_vm._v("难以做到对隔离人员的24小时监测")]),_c('li',[_vm._v("一人管理多人，社区人员人力不足")])])]),_c('div',{staticClass:"foot"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../../assets/imgs/xq14.png"),"alt":""}}),_c('p',[_vm._v("技防隔离，解放人力")])]),_c('ul',[_c('li',[_vm._v("对隔离人员进行24小时技防监控")]),_c('li',[_vm._v("隔离人员数据汇总分析")]),_c('li',[_vm._v("外出情况及时通知管理人员")]),_c('li',[_vm._v("一人管理多人轻松管理")])])])]),_c('img',{attrs:{"src":require("../../assets/imgs/xq10.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jujia"},[_c('p',{staticClass:"title"},[_vm._v(" 居家防疫 ")]),_c('img',{attrs:{"src":require("../../assets/imgs/xq11.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"anzhuang"},[_c('p',{staticClass:"title"},[_vm._v(" 小门神安装案例 ")]),_c('img',{attrs:{"src":require("../../assets/imgs/xq12.png"),"alt":""}})])
}]

export { render, staticRenderFns }