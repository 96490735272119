<template>
	<div class="container">
		<div class="iot">
			<div class="text">
				<div class="line"></div>
				<p class="title">NB-IOT智能门磁</p>
				<p class="subTitle">医学观察、居家隔离小门神！</p>
			</div>
			
			<div class="img-div">
				<img src="../../assets/imgs/xq1.png" alt="">
				<div class="right">
					<div class="right-de" v-for="item of xqArr" :key="item.id">
						<img :src="item.img" alt="">
						<p class="name">{{item.name}}</p>
					</div>
				</div>
			</div>
		</div>
		
		<div class="geli">
			<div class="text">
				<div class="line"></div>
				<p class="title">智慧隔离“小门神”</p>
				<p class="subTitle">功能全，更懂管理</p>
			</div>
			
			<div class="img-div">
				<img src="../../assets/imgs/xq8.png" alt="">
			</div>
		</div>
		
		<div style="background: #F4F8FF;">
			<div class="shouming">
				<img src="../../assets/imgs/xq9.png" alt="">
			</div>
		</div>
		
		<div class="advantage">
			<div class="text">
				<div class="line"></div>
				<p class="title">“小门神”智慧隔离服务系统优势</p>
				<p class="subTitle">多房源、网格化管理，智慧系统更高效</p>
			</div>
			<div class="bottom">
				<div class="left">
					<div class="top">
						<div class="title">
							<img src="../../assets/imgs/xq13.png" alt="">
							<p>隔离，人工监测弊端</p>
						</div>
						<ul>
							<li>无法确定需隔离人员是否外出</li>
							<li>难以做到对隔离人员的24小时监测</li>
							<li>一人管理多人，社区人员人力不足</li>
						</ul>
					</div>
					<div class="foot">
						<div class="title">
							<img src="../../assets/imgs/xq14.png" alt="">
							<p>技防隔离，解放人力</p>
							
						</div>
						<ul>
							<li>对隔离人员进行24小时技防监控</li>
							<li>隔离人员数据汇总分析</li>
							<li>外出情况及时通知管理人员</li>
							<li>一人管理多人轻松管理</li>
						</ul>
					</div>
				</div>
				<img src="../../assets/imgs/xq10.png" alt="">
			</div>
		</div>
		
		<div class="jujia">
			<p class="title">
				居家防疫
			</p>
			
			<img src="../../assets/imgs/xq11.png" alt="">
		</div>
		
		<div class="anzhuang">
			<p class="title">
				小门神安装案例
			</p>
			
			<img src="../../assets/imgs/xq12.png" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				xqArr: [
					{
						id: '2',
						img:require('@/assets/imgs/xq2.png'),
						name: '电话语音报警'
					},
					{
						id: '3',
						img:require('@/assets/imgs/xq3.png'),
						name: '短信推送报警'
					},
					{
						id: '4',
						img:require('@/assets/imgs/xq4.png'),
						name: '微信小程序报警'
					},
					{
						id: '5',
						img:require('@/assets/imgs/xq5.png'),
						name: '开门监测'
					},
					{
						id: '6',
						img:require('@/assets/imgs/xq6.png'),
						name: '定时布防'
					},
					{
						id: '7',
						img:require('@/assets/imgs/xq7.png'),
						name: '安装便捷'
					},
				]
			}
		},
	}
</script>

<style scoped>
.line {
	width: 61px;
	height: 7px;
	background: #184BAB;
}
.title {
	height: 36px;
	font-size: 36px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin: 14px 0 8px;
}
.subTitle {
	height: 16px;
	font-size: 16px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
}

.iot {
	width: 1200px;
	margin: 0 auto;
	padding-top: 126px;
	box-sizing: border-box;
}

.iot .text {
	position: relative;
	left: 116px;
}


.iot .img-div {
	margin-top: 89px;
	display: flex;
	justify-content: center;
}

.iot .img-div .right {
	margin-left: 135px;
}
.iot .img-div .right .right-de {
	display: flex;
	align-items: center;
	
}

.iot .img-div .right .right-de:not(:last-child) {
	margin-bottom: 22px;
}

.iot .img-div .right .right-de .name {
	font-size: 22px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	margin-left: 8px;
}

.geli {
	width: 1200px;
	margin: 0 auto;
	padding: 96px 116px 140px;
	box-sizing: border-box;
}


.geli .img-div {
	text-align: center;
	margin-top: 60px;
}

.shouming {
	width: 1200px;
	margin: 0 auto;
	padding: 56px 181px 68px;
	background: #F4F8FF;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

.advantage {
	width: 1200px;
	margin: 0 auto;
	padding: 143px 130px 118px;
	box-sizing: border-box;
}
.advantage  .bottom {
	display: flex;
	margin-top: 98px;
	position: relative;
	left: 139px;
}

.advantage  .bottom .left {
	margin-right: 11px;
}

.advantage  .bottom .left .top .title {
	display: flex;
	align-items: center;
	margin-bottom: 4px;
}
.advantage  .bottom .left .top .title p {
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}
.advantage  .bottom .left .top li {
	width: 318px;
	height: 42px;
	background: #F5F5F5;
	border-radius: 10px;
	line-height: 42px;
	font-size: 16px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}
.advantage  .bottom .left .top li:not(:last-child) {
	margin-bottom: 8px;
}
.advantage  .bottom .left .top li::before {
	content: '●';
	color: #333;
	margin: 18px;
}

.advantage  .bottom .left .foot .title {
	display: flex;
	align-items: center;
	margin-bottom: 4px;
}

.advantage  .bottom .left .foot .title p {
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color:#0356CE;
}

.advantage  .bottom .left .foot li {
	width: 318px;
	height: 42px;
	background: #D5E7FF;
	border-radius: 10px;
	line-height: 42px;
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #0356CE;
}
.advantage  .bottom .left .foot li:before {
	content: '●';
	color: #0356CE;
	margin: 18px;
}

.advantage  .bottom .left .foot li:not(:last-child) {
	margin-bottom: 8px;
}
ul {
	list-style: none;
}

.jujia {
	width: 1200px;
	margin: 0 auto;
	padding: 0 106px 136px; 
	box-sizing: border-box;
}

.jujia .title {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin-bottom: 27px;
}

.anzhuang {
	width: 1200px;
	margin: 0 auto;
	padding: 0 106px 152px;
	box-sizing: border-box;
}
.anzhuang .title {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin-bottom: 27px;
}
</style>